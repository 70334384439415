// -------------------------------------------------------------------------------
// To use register: app/javascript/packs/application.js
// or no editing in app/javascript/packs/application.js, instead, use below
// -------------------------------------------------------------------------------

// import TextareaAutogrow from "stimulus-textarea-autogrow"
//
// export default class extends TextareaAutogrow {
//   connect() {
//     super.connect()
//     console.log("Do what you want here.")
//   }
// }




// -------------------------------------------------------------------------------
// Before stimulus-textarea-autogrow upgrade to @hotwired/stimulus 3
// -------------------------------------------------------------------------------
// ------------------------
// ./utils
// ------------------------
// function debounce(callback, delay) {
//     let timeout
//     return (...args) => {
//         const context = this
//         clearTimeout(timeout)
//         timeout = setTimeout(() => callback.apply(context, args), delay)
//     }
// }
// export { debounce }

// ------------------------
// ./index.ts
// ------------------------

import { Controller } from '@hotwired/stimulus'
// import { debounce } from './utils'

export default class extends Controller {

  static values = { resizeDebounceDelay: Number }


  initialize() {
    this.autogrow = this.autogrow.bind(this)
  }


  connect() {
    this.element.style.overflow = 'hidden'
    this.element.style.resize = 'none'
    // const delay = this.resizeDebounceDelayValue || 100
    // this.onResize = delay > 0 ? debounce(this.autogrow, delay) : this.autogrow
    // this.onResize = delay > 0 ? setTimeout( this.autogrow(), delay  ) : this.autogrow()
    this.autogrow()
    this.element.addEventListener('input', this.autogrow)
    // window.addEventListener('resize', this.onResize)

  }


  disconnect() {
    // window.removeEventListener('resize', this.onResize)
    this.element.removeEventListener('input', this.autogrow)
  }

  // usage: setTimeout( () => this.testTimeout(), 5000)
  // testTimeout() {
  //   window.alert('test Timeout')
  // }

  // -------------------------------------------------------------
  // Ref. https://stackoverflow.com/questions/2948230/auto-expand-a-textarea-using-jquery
  // -------------------------------------------------------------
  getScrollHeight() {
    let savedValue = this.element.value
    this.element.value = ''
    this.element._baseScrollHeight = this.element.scrollHeight
    this.element.value = savedValue
  }


  onExpandableTextareaInput(){
    console.log(this.element.rows)

    let minRows = 3
    let rows
    !this.element._baseScrollHeight && this.getScrollHeight()

    this.element.rows = minRows
    // rows = Math.ceil((this.element.scrollHeight - this.element._baseScrollHeight) / 16)

    // 1 rem * 24 = 24
    // rows = Math.ceil((this.element.scrollHeight - this.element._baseScrollHeight) / 24)
    // 1.25 rem * 24 = 30
    rows = Math.ceil((this.element.scrollHeight - this.element._baseScrollHeight) / 30)
    this.element.rows = minRows + rows
    console.log(this.element.rows)
  }

  // -------------------------------------------------------------


  autogrow() {
    // this.element.style.height = 'auto' // Force re-print before calculating the scrollHeight value.
    // this.element.style.height = `${this.element.scrollHeight}px`
    // console.log(event.inputType)
    console.log("textarea grow to: " + this.element.style.height)

    // -------------------------------------------------------------
    // Only deal with \n
    // Ref. https://css-tricks.com/auto-growing-inputs-textareas/

    // let value = this.element.value
    // let numberOfLineBreaks = (value.match(/\n/g) || []).length;
    // let newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2;
    // this.element.style.height = newHeight + "px";

    // -------------------------------------------------------------


    // -------------------------------------------------------------
    // Ref. https://stackoverflow.com/questions/2948230/auto-expand-a-textarea-using-jquery
    // -------------------------------------------------------------
    this.onExpandableTextareaInput()

    // -------------------------------------------------------------




  }
}
