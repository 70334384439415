import { Controller } from "@hotwired/stimulus"

// --------------------------------------------------------------
// encrypt-storage using crypto-js
// --------------------------------------------------------------
// ref. https://github.com/michelonsouza/encrypt-storage
import { EncryptStorage } from 'encrypt-storage'

const mess_around = 'writed-e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855'
export const encryptStorage = new EncryptStorage(mess_around)

// --------------------------------------------------------------

export default class extends Controller {

  static classes = [ "autosave" ]

  // ----------------------------------------------------------------
  // save all form elements to LocalStorage
  // ----------------------------------------------------------------
  // Ref. https://stevepolito.design/blog/rails-auto-save-form-data/
  static targets = [ "form", "localhdd", "clearlocalhdd" ]


  connect() {
    // Create a unique key to store the form data into localStorage.
    // This could be anything as long as it's unique.
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
    this.localStorageKey    = window.location

    // Retrieve data from db as origin data
    this.db_json_data = JSON.stringify(this.getFormData())

    // Retrieve data from localStorage when the Controller loads.
    this.setFormData()
  }


  setFormData() {
    // See if there is data stored for this particular form.
    if(localStorage.getItem(this.localStorageKey) != null) {
      // We need to convert the String of data back into an Object.

      // --- plain text ---
      // const data = JSON.parse(localStorage.getItem(this.localStorageKey))
      // --- using encrypt-storage ---
      const data = encryptStorage.getItem(this.localStorageKey)
      this.formTarget.classList.add(this.autosaveClass)
      this.localhddTarget.classList.remove('d-none')

      // console.log(data)

      // This allows us to have access to this.formTarget in the loop below.
      const form = this.formTarget

      // Loop through each key/value pair and set the value on the corresponding form field.

      //    Arrow Function: (x) => x + 1  means function (x) { return x + 1  }  and "return" can be ignored
      //    ref. https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/Arrow_functions

      //    forEach
      //    ref. https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/forEach
      Object.entries(data).forEach((entry)=>{
        let name    = entry[0]
        let value   = entry[1]
        let input   = form.querySelector(`[name='${name}']`)
        input && (input.value = value)
      })

    }
  }


  getFormData() {
    // Construct a set of of key/value pairs representing form fields and their values.
    // https://developer.mozilla.org/en-US/docs/Web/API/FormData
    const form = new FormData(this.formTarget)
    let data = []

    // Loop through each key/value pair.
    // https://developer.mozilla.org/en-US/docs/Web/API/FormData/entries#example
    for(var pair of form.entries()) {

      // We don't want to save the authenticity_token to localStorage since that is generated by Rails.
      // https://guides.rubyonrails.org/security.html#cross-site-request-forgery-csrf
      // key   : pair[0]
      // value : pair[0]
      if (pair[0] != "authenticity_token") {
        data.push([pair[0], pair[1]])
      }

    }

    // Return the key/value pairs as an Object. Each key is a field name, and each value is the field value.
    // https://developer.mozilla.org/en-us/docs/Web/JavaScript/Reference/Global_Objects/Object/fromEntries
    return Object.fromEntries(data)
  }

  // COMPARE STRING:
  //   https://dmitripavlutin.com/compare-javascript-strings/
  compareFormData() {
    const data = this.getFormData()
    let new_json_data = JSON.stringify(data)

    // ----------- print to debut ----------
    // console.log(new_json_data.normalize())
    // console.log(this.db_json_data.normalize())
    // ----------- print to debut ----------

    if(this.db_json_data.normalize() === new_json_data.normalize()) {
      // origin data and edited data the same, assume it's the end of undo
      return "originData"
    } else {
      // origin data and edited data not the same, assume it's been edited
      return "editedData"
    }
  }


  saveToLocalStorage() {
    const data = this.getFormData()
    // Save the form data into localStorage. We need to convert the data Object into a String.

    // --- plain text ---
    // localStorage.setItem(this.localStorageKey, JSON.stringify(data))

    // --- using encrypt-storage ---
    if(this.compareFormData() == "editedData" ) {
      encryptStorage.setItem(this.localStorageKey, JSON.stringify(data))
      this.formTarget.classList.add(this.autosaveClass)
      this.localhddTarget.classList.remove('d-none')
    } else {
      this.clearLocalStorage()
    }

  }



  clearLocalStorage() {
    // See if there is data stored for this particular form.
    if(localStorage.getItem(this.localStorageKey) != null) {
      // Clear data from localStorage when the form is submitted.
      this.formTarget.classList.remove(this.autosaveClass)
      this.localhddTarget.classList.add('d-none')
      localStorage.removeItem(this.localStorageKey)
    }
  }

  doclearlocalhdd() {
    // console.log(document.URL)

    let this_confirm_msg = this.clearlocalhddTarget.getAttribute('data-auto-save-clearlocalhdd-confirm-msg')
    let this_second_confirm_msg = this.clearlocalhddTarget.getAttribute('data-auto-save-clearlocalhdd-second-confirm-msg')
    let this_canceled_msg = this.clearlocalhddTarget.getAttribute('data-auto-save-clearlocalhdd-canceled-msg')

    // console.log(this_alert_msg)

    // if(prompt("Type 'YeS' to confirm, this action is irreversible.", '') == 'YeS'){
    if(prompt(this_confirm_msg, '') == 'YeS'){
      if ( confirm(this_second_confirm_msg) ) {
        this.clearLocalStorage()
        console.log('localstorage data cleared !')
        Turbo.visit(document.URL, { action: "replace" })
      } else {
        alert(this_canceled_msg)
      }

    } else {
      alert(this_canceled_msg)
    }
  }




  // checkIncognitoMode() {
  //   if(typeof localStorage === 'object') {
  //     try{
  //       localStorage.setItem('localStorage', 1)
  //       localStorage.removeItem('localStorage')
  //     }catch(e){
  //       alert("Incognito Mode!")
  //     }
  //   }
  // }



}
