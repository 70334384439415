import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="markdown"
export default class extends Controller {
  static classes = [ "image" ]
  connect() {
    // ------------------------------------------------------
    // select elements
    // ------------------------------------------------------
    // --- for single select ---
    // failed: this is for single selector, not for an array
    // let imgs = document.querySelector('.markdown-body img')

    // --- for multiple select ---
    // success: this will assign an array
    // CSS selector online tester: https://try.jsoup.org
    let imgs = document.querySelectorAll(".markdown-body img:not(img[src*='#left'])")

    // ------------------------------------------------------

    imgs.forEach( (img)=> {
      // console.log(img)

      // ------------------------------------------------------
      // assign classes
      // ------------------------------------------------------
      // --- only for one class assign ---
      // failed: only assign the first class name

      // img.classList.add(this.imageClass)

      // --- multiple classes assign ---
      // success: all classes assigned to image, WITH 3 DOTS
      img.classList.add(...this.imageClasses)

      // ------------------------------------------------------
    })
  }
}
