import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // Ref. https://stimulus.hotwired.dev/reference/values#properties-and-attributes

  // Ref. https://dev.to/jacobdaddario/disable-with-using-stimulusjs-bco
  static values = { with: String }
  static targets = [ "imagefile" ]

  connect() {
    this.element.dataset['action'] = 'submit->disable#disableForm'

    if (!this.hasWithValue) {
      this.withValue = "Processing..."
    }
  }

  disableForm() {
    this.imagefileTarget.classList.add('d-none')
    this.submitButtons().forEach(button => {
      button.disabled = true
      button.value = this.withValue
    })
  }

  submitButtons() {
    return this.element.querySelectorAll("input[type='submit']")
  }

}
