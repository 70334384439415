// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
// import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle"
window.bootstrap = bootstrap

// ---------------------------------------------------------------------------------------------
// Import 3rd plugins
// ---------------------------------------------------------------------------------------------
// import "jquery/src/jquery"
// require("jquery/src/jquery")   //<---- Same as above

// import "bootstrap/dist/js/bootstrap"

// ------------------------
// Load for bootstrap-tooltips
// ------------------------
// -no use-
// import "@popperjs/core/dist/umd/popper"
// import "bootstrap/dist/js/bootstrap"
// import "bootstrap/dist/js/bootstrap.bundle"
// -no use-

// ref. https://laracasts.com/index.php/discuss/channels/elixir/use-bootstrap-5-module-in-blade
// import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle"
// window.bootstrap = bootstrap
// ------------------------

// -----------------------------------------------
// Move the fontawesome import to application.js
// (webpacker is easier than asset pipeline for fontawesome)
// -----------------------------------------------
// --- Load all icon using web fonts ---
// import "@fortawesome/fontawesome-free/css/all.css"


// --- Avoid flicker while change pages ---
// Ref. https://fontawesome.com/v6/docs/web/use-with/rails-turbolinks#using-webpacker-and-fortawesome-fontawesome-svg-core
// yarn add @fortawesome/fontawesome-svg-core @fortawesome/fontawesome-free/js/all.js
import { config, library, dom } from '@fortawesome/fontawesome-svg-core'

// Change the config to fix the flicker
config.mutateApproach = 'sync'

import {
  faGithub
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faGithub
)
dom.watch()

// --- Load all icon using svg (js)---
import "@fortawesome/fontawesome-free/js/all.js"


// -----------------------------------------------

// --- local-time ---
// ### Method 1 (Webpacker 5.x perfered) ###
import LocalTime from "local-time"
LocalTime.start()

// ### Method 2 (Webpacker 4.x perfered)  ###
// require("local-time").start()


// ---------------------------------------------------------------------------------------------
// chartkick
// ---------------------------------------------------------------------------------------------
import "chartkick/chart.js"

// ---------------------------------------------------------------------------------------------
// Rails 6.1 Default
// ---------------------------------------------------------------------------------------------
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// ### Method 1 (Webpacker 5.x perfered) ###
// import Rails from "@rails/ujs"
// import "@hotwired/turbo-rails"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"
//
// Rails.start()
// ActiveStorage.start()

// ### Method 2 (Webpacker 4.x perfered)  ###
// require("@rails/ujs").start()
// // import "@hotwired/turbo-rails"
// require("@rails/activestorage").start()
// require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)



// ---------------------------------------------------------------------------------------------
// Stimulus Components (ref. https://stimulus-components.netlify.app/)
// ---------------------------------------------------------------------------------------------
// wait for stimulus 3 version
// import { Application } from "stimulus"
// const application = Application.start()
//
// import TextareaAutogrow from "stimulus-textarea-autogrow"
// application.register("textarea-autogrow", TextareaAutogrow)



// ---------------
// This will always trigger redirect_to after finishing submit
// ---------------
// import Autosave from "stimulus-rails-autosave"
// application.register("autosave", Autosave)


// ---------------------------------------------------------------------------------------------
// Custom JS
// ---------------------------------------------------------------------------------------------
// Ref. https://medium.com/@ekaterinalait_15121/a-guide-to-custom-and-third-party-javascript-with-rails-6-webpacker-and-turbolinks-9b36942b8789

// import "./custom/clean-blog"
// require("./custom/clean-blog")

// import gtag from "./custom/ga-writed"
import "./custom/ga-writed"

