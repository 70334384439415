import { Controller } from "@hotwired/stimulus"
import insertTextAtCursor from 'insert-text-at-cursor'

export default class extends Controller {

  // static targets = [ "image", "content" ]
  static targets = [ "content" ]

  // static values = { imgname: String }

  connect() {
    document.addEventListener('turbo:frame-load', (event) => {
      console.log('Bootstrap 5 tooltips enabled!')
      let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
      })
    }) // end turbo:load
  }

  appendTo() {
    // ------------------------------------------------------------------------
    // image src url
    // ------------------------------------------------------------------------
    // --- failed ---
    // let this_img_url = this.imageTarget.src

    // --- success ---
    // let this_img_url = $(event.target).attr('src')

    // ref. https://symfonycasts.com/screencast/stimulus/current-target

    // --- Full URL with host ---
    // let this_img_url = event.currentTarget.getAttribute('src')

    // --- Full URL without host ---
    // console.log(event.currentTarget.getAttribute('src'))

    let this_img_url = event.currentTarget.getAttribute('data-article-append-imgurl-value')

    // ------------------------------------------------------------------------
    // image file name
    // ------------------------------------------------------------------------
    // let this_img_name = this
    // let this_img_name = $(event.target).attr('data-article-append-imgname-value')
    let this_img_name = event.currentTarget.getAttribute('data-article-append-imgname-value')


    // console.log(this_img_name)


    // ------------------------------------------------------------------------
    // image markdown
    // ------------------------------------------------------------------------
    let img_md = '![' + this_img_name + '](' + this_img_url + ')'

    // ------------------------------------------------------------------------
    // append image markdown into textarea
    // ------------------------------------------------------------------------
    // this.contentTarget.value += img_md
    insertTextAtCursor(this.contentTarget, img_md)
  }

}
