// -------------------------------------
// config
// -------------------------------------
// const run_at_env = "development"
// const run_at_env = "production"
// const run_at_env = "dev-writed.me"
const run_at_env = "writed.me"
const run_ga_code = "G-7HD09V2YD1"
const run_cross_domain = "writed.me"
// -------------------------------------


// if (process.env.NODE_ENV === run_at_env) {
if (window.location.hostname === run_at_env) {
  // --------------------------------------------------------------
  // <script async src="https://www.googletagmanager.com/gtag/js?id=G-7HD09V2YD1"></script>
  // --------------------------------------------------------------
  // Ref. https://stackoverflow.com/questions/62910140/dynamically-injecting-google-analytics-code-via-javascript
  // Ref. https://stackoverflow.com/questions/57351219/loading-google-analytics-gtag-js-using-webpack-after-everything-else-loads
  const ga_script = window.document.createElement('script')

  // Ref. https://developer.mozilla.org/en-US/docs/Web/HTML/Element/script
  ga_script.async = true
  ga_script.src = "https://www.googletagmanager.com/gtag/js?id=" + run_ga_code

  // const head = document.getElementsByTagName("head")[0]
  // head.insertBefore(ga_script, head.children[1])

  const elem = window.document.getElementsByTagName('script')[0]

  // appendChild: To make sure gtag is after hotwired/turbo & webpack combined script
  // Ref. https://developer.mozilla.org/en-US/docs/Web/API/Node/appendChild
  // elem.parentNode.appendChild(ga_script)

  // (Recommended for GA purpose)insertBefore: To make sure gtag is before hotwired/turbo & webpack combined script
  elem.parentNode.insertBefore(ga_script, elem)

  //-------- gtag -----
  window.dataLayer = window.dataLayer || []
  function gtag(){dataLayer.push(arguments)}
  gtag('js', new Date())
  //-------- gtag -----

  // --------------------------------------------------------------

  document.addEventListener('turbo:load', (event) => {
    if (! document.documentElement.hasAttribute("data-turbo-preview")) {
      console.log('gtag Loaded: ' + process.env.NODE_ENV + ' @ ' + new Date().toISOString())

      // -------------- gtag config -------------
      // cross-domain: https://developers.google.com/analytics/devguides/collection/gtagjs/cross-domain
      // gtag config: https://developers.google.com/analytics/devguides/collection/gtagjs/pages
      gtag('config', run_ga_code, {
        'linker': {
          'domains': [ run_cross_domain ]
        }
      })
      // -------------- gtag config -------------

    } // if data-turbo-preview
  }) // end turbo:load

} // end env == production




// export default gtag
